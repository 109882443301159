import React from 'react';

function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

function isObject(objectToCheck) {
  return typeof objectToCheck === 'object'
}

const isString = value => typeof value === 'string';

function toTwitter(str) {
  if (!isString(str)) {
    return str;
  }

  if (str[0] === '@') {
    return str;
  }

  return `@${str}`;
}

function postToSeoOptions( pageItem, pageSchema, siteSchema) {
  let page = pageItem;
  let pageSchemaObject = (
    isString(pageSchema) ? JSON.parse(pageSchema) : pageSchema
  )

  if (!isObject(pageSchemaObject) && isString(page?.schema?.raw)) {
    pageSchemaObject = JSON.parse(page?.schema?.raw ?? '');
  }

  const siteSchemaObject = (
    isString(siteSchema) ? JSON.parse(siteSchema) : siteSchema
  );

  const title =
  page?.opengraphTitle ||
  page?.title ||
  page?.twitterTitle ||
    siteSchemaObject?.siteName ||
    '';

  const description =
  page?.metaDesc || page?.opengraphDescription || page?.twitterDescription || '';

  let canonical = page?.canonical || page?.opengraphUrl || page.uri;
  canonical = canonical?.toString().replace("https://natlands.org", "https://www.natlands.org");
  let opengraphUrl = page?.opengraphUrl;
  opengraphUrl = opengraphUrl?.toString().replace("https://natlands.org", "https://www.natlands.org");

  const locale = page?.locale || 'en_US';
  const cornerstone = page?.cornerstone;
  const focusKeyword = page?.focuskw;
  const keywords = page?.metaKeywords;
  const readingTime = page?.readingTime;

  const robotsIndex =
  page?.metaRobotsNoindex === 'noindex' ? 'noindex' : 'index';
  const robotsFollow =
  page?.metaRobotsNofollow === 'nofollow' ? 'nofollow' : 'follow';

  const image = {
    sourceUrl:
    page?.opengraphImage?.sourceUrl ||
    page?.twitterImage?.sourceUrl ||
      siteSchemaObject?.companyLogo?.sourceUrl,
    altText:
    page?.opengraphImage?.altText ||
    page?.twitterImage?.altText ||
      siteSchemaObject?.companyLogo?.altText,
    srcSet:
    page?.opengraphImage?.srcSet ||
    page?.twitterImage?.srcSet ||
      siteSchemaObject?.companyLogo?.srcSet,
  };

  return {
    title,
    description,
    locale,
    canonical,
    cornerstone,
    focusKeyword,
    keywords,
    pageSchema: pageSchemaObject,
    siteSchema: siteSchemaObject,
    readingTime,
    robots: {
      index: robotsIndex,
      follow: robotsFollow,
    },
    og: {
      title: page?.opengraphTitle || title,
      description: page?.opengraphDescription || description,
      image: (page?.opengraphImage) || image,
      type: page?.opengraphType || 'website',
      url: opengraphUrl,
      author: page?.opengraphAuthor,
      publisher: page?.opengraphPublisher,
      modifiedTime: page?.opengraphModifiedTime,
      publishedTime: page?.opengraphPublishedTime,
    },
    twitter: {
      title: page?.twitterTitle || title,
      description: page?.twitterDescription || description,
      image: (page?.twitterImage) || image,
      creator: page?.author?.node?.seo?.social?.twitter,
    },
  };
}

export default function YoastSEO({
  meta,
  page,
  pageSchema,
  siteSchema,
  MetaRenderElement,
  processSchema,
}) {
  const seoOptions = postToSeoOptions(page || {}, pageSchema, siteSchema);

  const {
    title,
    description,
    canonical,
    locale,
    keywords,
    robots,
    og,
    twitter,
  } = seoOptions;

  let newTitle = title;
  const { convert } = require('html-to-text');
  newTitle = convert(newTitle);

  let { pageSchema: pageSchemaObj, siteSchema: siteSchemaObj } = seoOptions;

  let pageSchemaStr = isObject(pageSchemaObj)
    ? JSON.stringify(pageSchemaObj)
    : undefined;
  let siteSchemaStr = isObject(siteSchemaObj)
    ? JSON.stringify(siteSchemaObj)
    : undefined;

  if (isFunction(processSchema)) {
    if (isString(pageSchemaStr)) {
      pageSchemaStr = processSchema(pageSchemaStr);
      pageSchemaObj = JSON.parse(pageSchemaStr);
    }

    if (isString(siteSchemaStr)) {
      siteSchemaStr = processSchema(siteSchemaStr);
      siteSchemaObj = JSON.parse(siteSchemaStr);
    }
  }

  return (
    <MetaRenderElement>
      {meta}
      <title>{newTitle}</title>
      <meta
        name="robots"
        content={`max-snippet:-1, max-image-preview:large, max-video-preview:-1, index, ${robots.follow}`}
      />
      {isString(canonical) && <link rel="canonical" href={canonical} />}
      {isString(description) && (
        <meta name="description" content={description} />
      )}
      {isString(keywords) && <meta name="keywords" content={keywords} />}
      <meta property="og:locale" content={locale} />
      <meta property="og:type" content={og?.type} />
      <meta property="og:site_name" content="Natural Lands" />
      {isString(og?.title) && <meta property="og:title" content={og?.title} />}
      {isString(og?.description) && (
        <meta property="og:description" content={og?.description} />
      )}
      {isString(og?.author) && (
        <meta property="og:author" content={og?.author} />
      )}
      {isString(og?.url) && <meta property="og:url" content={og?.url} />}
      {isString(og?.publishedTime) && (
        <meta property="article.published_time" content={og?.publishedTime} />
      )}
      {isString(og?.modifiedTime) && (
        <meta property="article.modified_time" content={og?.modifiedTime} />
      )}
      {isString(og?.image?.sourceUrl) && (
        <>
          <meta property="og:image" content={og?.image?.sourceUrl} />
          {isString(og?.image?.altText) && (
            <meta property="og:image:alt" content={og?.image?.altText} />
          )}
          {isString(og?.image?.mimeType) && (
            <meta property="og:image:type" content={og?.image?.mimeType} />
          )}
          {og?.image?.mediaDetails?.width && (
            <meta property="og:image:width" content={og?.image?.mediaDetails?.width} />
          )}
          {og?.image?.mediaDetails?.height && (
            <meta property="og:image:height" content={og?.image?.mediaDetails?.height} />
          )}
        </>
      )}
      {isString(twitter?.title) && (
        <meta name="twitter:title" content={twitter?.title} />
      )}
      {isString(twitter?.description) && (
        <meta name="twitter:description" content={twitter?.description} />
      )}
      {isString(twitter?.image?.sourceUrl) && (
        <>
          <meta name="twitter:image" content={twitter?.image?.sourceUrl} />
          {isString(twitter?.image?.altText) && (
            <meta name="twitter:image:alt" content={twitter?.image?.altText} />
          )}
        </>
      )}
      {isString(twitter?.creator) && (
        <meta name="twitter:creator" content={toTwitter(twitter?.creator)} />
      )}
      {isString(pageSchemaStr) && (
        <script type="application/ld+json">{pageSchemaStr.toString().replace('https://natlands.org', 'https://www.natlands.org')}</script>
      )}
    </MetaRenderElement>
  );
}
